import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./style.css";
import { Link } from "react-router-dom";
function Footer() {
  const year = new Date().getFullYear();

  return (
    <footer className="footer mt-auto ">
      <Container>
        <Row className="pb-3">
          <Col className="text-center">
            <u>
              <Link
                to="/privacy-policy"
                className=" ps-0 ps-lg-3  "
              >
                Политика конфиденциальности
              </Link>
            </u>
          </Col>
        </Row>
      </Container>
      <Container fluid className="py-3 bg-secondary">
        <Row>
          <Col className="text-white-50 text-center  ">
            © АО «Сигнал-КОМ» 1990-{year}
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
