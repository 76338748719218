import { useState, useContext, useEffect } from "react";
import bigInt from "big-integer";
import moment from "moment/moment";
import {
  Col,
  Row,
  Button,
  Dropdown,
  DropdownButton,
  Spinner,
} from "react-bootstrap";
import { BsCheckCircle } from "react-icons/bs";
import { TbFileCertificate } from "react-icons/tb";
import apiContext from "../../../../context/Api";
import { gPathKeyContainer } from "../../../../store/constants";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import Context from "../../../../context/context";

const Step_3 = (props) => {
  const initialValues = {
    bank: "Первый Русский Банк",
    account: "4018024231000000000001",
    name: "Иванов Иван Иванович",
    inn: "770132187321",
    sum: "0",
  };

  const [disabled, setDisabled] = useState(false);
  const [disabledSig, setDisabledSig] = useState(false);
  const [values, setValues] = useState(initialValues);
  const [show, setShow] = useState(true);
  const [error, setError] = useState("");
  const [PKCS7, setPKCS7] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const [verificationResult, setVerificationResult] = useState({});
  const [initButton, setInitButton] = useState("SmartToken-PRO");
  const [certificateName, setCertificateName] = useState([]);
  const [typeSig, setTypeSig] = useState();
  const { certificateList, setCertificate, certificate, setResetTabStep, resetTabStep } = useContext(Context);
  const [selectedCertificate, setSelectedCertificate] = useState("");
  const [print, setPrint] = useState("");
  const [doc, setDoc] = useState("");

  const {
    /** @type Api */
    api,
  } = useContext(apiContext);

  const handleToken = (e) => {
    setInitButton(e);
  };

  const handleChange = (e) => {
    setSelectedCertificate(e.target.value);
  };


  useEffect(() => {
    if(resetTabStep) {
      setResetTabStep(false)
      setShow(true);
      setDisabled(false);
      setError("");
      setVerificationResult({});
    }
  },[resetTabStep])

  useEffect(() => {
    const getNameCertificate = async () => {
      let arrCert = [];
      for (let i in certificateList) {
        await window.scsignplugin
          .getCertificateProperty(certificateList[i], "subject")
          .then(
            (sertName) => {
              arrCert.push({
                name: sertName,
                pem: String(certificateList[i]),
                id: i,
              });
            },
            (error) => {
              console.log(error);
            }
          );
      }
      setCertificateName(arrCert);

      if (!selectedCertificate) {
        // add the default value to select with cert
        setSelectedCertificate(certificateList[0]);
        setCertificate(certificateList[0]);
      }
    };
    getNameCertificate();
  }, [certificateList]);

  const convertDate = (dateString) => {
    let date = moment(dateString);
    let formattedDate = date.format("DD.MM.Y hh:mm:ss");
    return formattedDate;
  };
  // function downloadXML(xmlString, fileName) {
  //   const blob = new Blob([xmlString], { type: "application/xml" });
  //   const url = URL.createObjectURL(blob);
  //   const link = document.createElement("a");
  //   link.download = fileName;
  //   link.href = url;
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  //   URL.revokeObjectURL(url);
  // }
  // Verification sertficate

  async function Verification(sig, docBase64) {
    const sigBase64 = btoa(sig);

    setIsLoader(true);
    setDisabledSig(1);
    return api.opverifysig(sigBase64, docBase64).then((result) => {
      const { content, status, error } = result;

      if (error) {
        setIsLoader(0);
        setError(error);
        setDisabledSig(0);
      }
      switch (status.code) {
        case 200:
          setIsLoader(false);
          setDisabledSig(false);

          let xmlArr = [];
          let decodedBase64 = atob(content.verification);
          let valid = "";
          let validColorClass = "";
          let diagnostics = "";
          let startDate = "";
          let endDate = "";
          let sigValErr = [];
          const parser = new DOMParser();
          const xmlDoc = parser.parseFromString(
            decodedBase64,
            "application/xml"
          );

          // const xmlString = new XMLSerializer().serializeToString(xmlDoc);
          //  downloadXML(xmlString, "myFile.xml");

          const resultMinor = xmlDoc.getElementsByTagName("ResultMinor")[0].textContent;
          if (
            resultMinor ===
            "urn:oasis:names:tc:dss:1.0:resultminor:valid:signature:OnAllDocuments"
          ) {
            valid = "Подпись действительна";
            validColorClass ="text-success"
          } else {
            valid = "Подпись недействительна";
            validColorClass ="text-danger"
            if (xmlDoc.getElementsByTagName("ns3:DetailedSignatureReport")) {
              let zz = xmlDoc.getElementsByTagName("ns3:ResultMajor");

              for (let i = 0; i < zz.length; i++) {
                let zz =
                  xmlDoc.getElementsByTagName("ns3:ResultMajor")[i].textContent;
                sigValErr.push(zz + "\n");
              }
            }
            diagnostics = sigValErr;
          }

          //Parce xml
          const signingTime =
            xmlDoc.getElementsByTagName("SigningTime")[0].textContent;
          const verificationTime =
            xmlDoc.getElementsByTagName("VerificationTime")[0].textContent;
          const issuerName =
            xmlDoc.getElementsByTagName("ns2:X509IssuerName")[0].textContent;
          const subject =
            xmlDoc.getElementsByTagName("SignerIdentity")[0].textContent;

          const serialNumber = xmlDoc.getElementsByTagName(
            "ns2:X509SerialNumber"
          )[0].textContent;
          const bigIntValue = bigInt(serialNumber);
          const serial = bigIntValue.toString(16).toUpperCase();

          if (xmlDoc.getElementsByTagName("ns6:NotBefore")[0]) {
            startDate =
              xmlDoc.getElementsByTagName("ns6:NotBefore")[0].textContent;
            endDate =
              xmlDoc.getElementsByTagName("ns6:NotAfter")[0].textContent;
          } else if (xmlDoc.getElementsByTagName("ns4:NotBefore")[0]) {
            startDate =
              xmlDoc.getElementsByTagName("ns4:NotBefore")[0].textContent;
            endDate =
              xmlDoc.getElementsByTagName("ns4:NotAfter")[0].textContent;
          } else if (xmlDoc.getElementsByTagName("ns3:NotBefore")[0]) {
            startDate =
              xmlDoc.getElementsByTagName("ns3:NotBefore")[0].textContent;
            endDate =
              xmlDoc.getElementsByTagName("ns3:NotAfter")[0].textContent;
          }

          //Create data array
          xmlArr.push({
            resultMajor: resultMinor,
            order: "Платежное поручение",
            sigIsInvalid: valid,
            validColorClass:validColorClass,
            diagnostics: diagnostics,
            sigTime: signingTime,
            subject: subject,
            disconnected: typeSig,
            period: convertDate(startDate) + " - " + convertDate(endDate),
            publisher: issuerName,
            verificationTime: verificationTime,
            serialNumber: serial,
            print: print,
          });

          setVerificationResult(xmlArr);

          break;
        case 400:
          setIsLoader(false);

          break;
        case 500:
          setIsLoader(false);
          setError("Произошла ошибка при обращении к серверу" + status.message);

          break;
        case 502:
          setIsLoader(false);
          setError("Произошла ошибка при обращении к серверу");
          break;
        default:
          setIsLoader(false);
          console.log("error server");
          break;
      }
    });
  }

  //utf8 to 1251 converter (1 byte format, RU/EN support only + any other symbols)
  function utf8_to_1251_byte_array(aa) {
    var bb = [],
      c = 0;
    for (var i = 0; i < aa.length; i++) {
      c = aa.charCodeAt(i);
      if (c > 127) {
        if (c > 1024) {
          if (c == 1025) {
            c = 1016;
          } else if (c == 1105) {
            c = 1032;
          }
          bb.push(c - 848);
        }
      } else {
        bb.push(c);
      }
    }
    return bb;
  }

  const handlerCheckCertificate = () => {
    if (PKCS7) {
      setTypeSig("отсоединенная");
    } else {
      setTypeSig("присоединенная");
    }

    Verification(PKCS7, doc);
  };

  function _safetouchPackText1251(text) {
    const lines = text.split(/\r?\n/);
    const linesCount = Math.min(15, lines.length); //f1-f5 visible, f6-fe reserved, ff nonvisible
    var formatLength = 9;
    for (var z = 0; z < linesCount; z++) {
      formatLength += 3 + lines[z].length;
    }
    const buffer = new ArrayBuffer(formatLength);
    const dataBuffer = new DataView(buffer);
    var offset = 0;
    dataBuffer.setUint8(offset++, 0x01); //magic tlv
    dataBuffer.setUint16(offset, 0x0002, false /* littleEndian */); //magic tlv size
    offset += 2;
    dataBuffer.setUint16(offset, 0x3535, false /* littleEndian */); //magic value
    offset += 2;
    dataBuffer.setUint8(offset++, 0x02); //class tlv tag
    dataBuffer.setUint16(offset, 0x0001, false /* littleEndian */); //class tlv size
    offset += 2;
    dataBuffer.setUint8(offset++, 0xff); //tlv plaintext tag
    for (var i = 0; i < linesCount; i++) {
      var lines1251 = utf8_to_1251_byte_array(lines[i]);
      dataBuffer.setUint8(offset++, 0xf1 + i); //line tlv tag
      dataBuffer.setUint16(offset, lines1251.length, false /* littleEndian */); //line size
      offset += 2;
      for (var j = 0; j < lines1251.length; j++) {
        dataBuffer.setUint8(offset++, lines1251[j]); //symbol
      }
    }
    return buffer;
  }

  function _printSign(signature) {
    setIsLoader(false);
    setPKCS7(signature);
    setShow(false);
  }

  function _getSignText1251(cert, isInContext, msgFormat) {
    if (msgFormat === "textSafeTouch") {
      let arrayBuffer = _safetouchPackText1251(isInContext);

      let base64String = btoa(
        String.fromCharCode.apply(null, new Uint8Array(arrayBuffer))
      );

      window.scsignplugin
        .signBinaryData(base64String, gPathKeyContainer, cert)
        .then(_printSign, function (error) {
          console.log(error);
        });
    } else if (msgFormat === "formatPinPad") {
      let arrayBuffer = utf8_to_1251_byte_array(isInContext);

      let base64String = btoa(
        String.fromCharCode.apply(null, new Uint8Array(arrayBuffer))
      );

      setDoc(base64String);
      window.scsignplugin
        .signBinaryData(base64String, gPathKeyContainer, cert)
        .then(_printSign, function (error) {
          setDisabled(false);
          setIsLoader(false);
          setError(error.message);
        });
    }
  }
  //PinPad Format
  function _pinpadPackFormat(bank, name, inn, sum) {
    let textReslut = "<!PINPADFILE RU>";
    textReslut += "<N>" + "Банк получателя:";
    textReslut += "<V>" + bank;
    textReslut += "<N>" + "ФИО получателя:";
    textReslut += "<V>" + name;
    textReslut += "<N>" + "ИНН получателя:";
    textReslut += "<V>" + inn;
    textReslut += "<N>" + "Сумма перевода:";
    textReslut += "<V>" + sum;
    return textReslut;
  }

  // function utf8_to_1251_byte_array(aa) {
  //   var bb = [],
  //     c = 0;
  //   for (var i = 0; i < aa.length; i++) {
  //     c = aa.charCodeAt(i);
  //     if (c > 127) {
  //       if (c > 1024) {
  //         if (c == 1025) {
  //           c = 1016;
  //         } else if (c == 1105) {
  //           c = 1032;
  //         }
  //         bb.push(c - 848);
  //       }
  //     } else {
  //       bb.push(c);
  //     }
  //   }
  //   return bb;
  // }

  const handlerCheckDocument = () => {
    setShow(true);
    setDisabled(false);
    setError("");
    setVerificationResult({});
  };

  // submit form
  async function handleSubmit(e) {
    setError("");
    setIsLoader(true);
    setValues((values) => ({
      ...values,
      bank: e.bank,
      name: e.name,
      inn: e.inn,
      sum: e.sum,
      content: "",
      error: "",
    }));

    const context = _pinpadPackFormat(e.bank, e.name, e.inn, e.sum);

    let msgFormat = "formatPinPad";
    setDisabled(true);
    _getSignText1251(selectedCertificate, context, msgFormat);
  }

  return (
    <>
      {verificationResult.length ? (
        <>
          <h5 className="text-center">Результат проверки электронной подписи</h5>
          <Col className="mt-4">
            <table className="table table-bordered">
              <thead></thead>
              <tbody>
                <tr>
                  <th scope="row">Название документа</th>
                  <td>{verificationResult[0].order}</td>
                </tr>
                <tr>
                  <th scope="row">Результат проверки</th>
                  <td className={verificationResult[0].validColorClass}><b>{verificationResult[0].sigIsInvalid}</b> </td>
                </tr>
                {verificationResult[0].diagnostics ? (
                  <tr>
                    <th scope="row">Дополнительная информация</th>
                    <td>{verificationResult[0].diagnostics}</td>
                  </tr>
                ) : (
                  ""
                )}
                <tr>
                  <th scope="row" colSpan={3}>
                    Дополнительная информация о подписи
                  </th>
                </tr>

                <tr>
                  <th scope="row">Время подписи</th>
                  <td>{convertDate(verificationResult[0].sigTime)}</td>
                </tr>
                {/* <tr>
                  <th scope="row">Тип подписи</th>
                  <td>{typeSig}</td>
                </tr> */}
                <tr>
                  <th scope="row" colSpan={3}>
                    Информация о серификате
                  </th>
                </tr>
                <tr>
                  <th scope="row">Субъект</th>
                  <td>{verificationResult[0].subject}</td>
                </tr>
                <tr>
                  <th scope="row">Издатель</th>
                  <td>{verificationResult[0].publisher}</td>
                </tr>
                <tr>
                  <th scope="row">Серийный номер</th>
                  <td>{verificationResult[0].serialNumber}</td>
                </tr>

                <tr>
                  <th scope="row">Срок действия</th>
                  <td>{verificationResult[0].period}</td>
                </tr>

                {verificationResult[0].print ? (
                  <tr>
                    <th scope="row">Отпечаток</th>
                    <td>{verificationResult[0].print}</td>
                  </tr>
                ) : (
                  ""
                )}
              </tbody>
            </table>

            <div className="text-center">
              <Button variant={"secondary"} onClick={handlerCheckDocument}>
                Подписать другой документ
              </Button>
            </div>
          </Col>
        </>
      ) : (
        <Row>
          {show ? (
            <>
              <h5 className="text-center">Подпись документов</h5>
              <p className="text-center">Заполните поля формы и выберите сертификат для подписания тестового платежного документа.</p>
              <Formik
                initialValues={{
                  bank: values.bank,
                  account: values.account,
                  name: values.name,
                  inn: values.inn,
                  sum: values.sum,
                }}
                onSubmit={handleSubmit}
                validationSchema={Yup.object({
                  bank: Yup.string()
                    .required("Необходимо указать название банка")
                    .min(3, "Минимальное количество символов 3")
                    .max(50, "Максимальное количество символов 50"),

                  name: Yup.string()
                    .matches(/^[а-яё. -]+$/iu, "Укажите Фамилию Имя Отчество")
                    .max(50, "Максимальное количество символов 50")
                    .min(5, "Минимальное количество символов 5")
                    .required("Необходимо указать ФИО"),

                  inn: Yup.string()
                    .required("Необходимо указать верный ИНН")
                    .min(
                      12,
                      "Неверно указан ИНН, минимальное количество цифр 12"
                    )
                    .max(12, "Максимальное количество цифр 12")
                    .typeError("Только цифры").matches(
                      "^[0-9;]+$",
                      "Неверно указан ИНН, допускается только цифры"
                    ),

                  sum: Yup.number()
                    .required("Необходимо указать cумму")
                    .typeError("Только цифры"),
                })}
              >
                {(formik) => (
                  <Form>
                    <Row>
                      <Col md={12}>
                        <label htmlFor="bank">
                          Банк получателя:
                          <span className="text-danger">*</span>
                        </label>
                        <Field
                          name="bank"
                          className={
                            formik.touched.bank && formik.errors.bank
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          type="text"
                        />
                        {formik.touched.bank && formik.errors.bank ? (
                          <div className="invalid-feedback">
                            {formik.errors.bank}
                          </div>
                        ) : null}
                      </Col>
                      <Col md={12}>
                        <label htmlFor="name">
                          ФИО получателя:
                          <span className="text-danger">*</span>
                        </label>
                        <Field
                          name="name"
                          className={
                            formik.touched.name && formik.errors.name
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          type="text"
                        />
                        {formik.touched.name && formik.errors.name ? (
                          <div className="invalid-feedback">
                            {formik.errors.name}
                          </div>
                        ) : null}
                      </Col>
                      <Col md={12}>
                        <label htmlFor="inn">
                          ИНН получателя:
                          <span className="text-danger">*</span>
                        </label>
                        <Field
                          name="inn"
                          className={
                            formik.touched.inn && formik.errors.inn
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          type="text"
                        />
                        {formik.touched.inn && formik.errors.inn ? (
                          <div className="invalid-feedback">
                            {formik.errors.inn}
                          </div>
                        ) : null}
                      </Col>
                      <Col md={12}>
                        <label htmlFor="sum">
                          Сумма перевода:
                          <span className="text-danger">*</span>
                        </label>
                        <Field
                          name="sum"
                          className={
                            formik.touched.sum && formik.errors.sum
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          type="text"
                        />
                        {formik.touched.sum && formik.errors.sum ? (
                          <div className="invalid-feedback">
                            {formik.errors.sum}
                          </div>
                        ) : null}
                      </Col>
                      <Col md={12}>
                        <label htmlFor="dropdown-button-drop-down">
                          Выбор сертификата:
                        </label>
                        <div className="input-group mb-3">
                          <DropdownButton
                            id={`dropdown-button-drop-down`}
                            drop="down"
                            variant="secondary"
                            title={initButton}
                            onSelect={handleToken}
                          >
                            {/*<Dropdown.Divider />*/}
                            <Dropdown.Item
                              eventKey="SmartToken-PRO"
                              disabled={
                                initButton === "SmartToken-PRO" ? true : false
                              }
                            >
                              SmartToken-PRO
                            </Dropdown.Item>
                          </DropdownButton>

                          <select
                            value={selectedCertificate}
                            onChange={handleChange}
                            aria-label="Default select example"
                            className="form-select"
                          >
                            <option
                              className={certificateList ? "d-none" : "d-block"}
                            >
                              Нет сертификатов на токене
                            </option>
                            {certificateName.map((option, index) => (
                              <option value={option.pem} key={index}>
                                {option.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="d-grid">
                          <Button
                            type="submit"
                            className="btn btn-lg btn-secondary mt-4"
                            disabled={formik.isSubmitting || disabled}
                          >
                            {isLoader ? (
                              <>
                                <span className="d-inline">Запрос...</span>
                                <Spinner
                                  className="ms-3"
                                  as="span"
                                  animation="grow"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                />
                              </>
                            ) : (
                              "Подписать"
                            )}
                            {/* {disabled
                            ? "Данные подписаны"
                            : formik.isSubmitting
                            ? "Запрос..."
                            : "Подписать"} */}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </>
          ) : (
            <>
              <Col className="text-center">
                <h2>Подпись сформирована</h2>
                <p>Тестовый документ успешно подписан! Вы можете проверить электронную подпись или подписать новый документ.</p>
                <BsCheckCircle className="my-3 text-success" size={60} />
                <p className="mt-0">Подписанные данные</p>
                <div className="form-floating mb-3">
                  <textarea
                    disabled
                    className="form-control bg-success text-white"
                    id="floatingTextarea2"
                    style={{ height: "110px" }}
                    defaultValue={PKCS7}
                  ></textarea>
                </div>
                <div className="d-grid gap-2  justify-content-center mb-3 mt-4">
                  <Button
                    onClick={handlerCheckCertificate}
                    disabled={disabledSig}
                    variant="secondary"
                    size="lg"
                    style={{ minWidth: "350px" }}
                  >
                    <TbFileCertificate />
                    {isLoader ? (
                      <>
                        <span className="d-inline">Запрос...</span>
                        <Spinner
                          className="ms-3"
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      </>
                    ) : (
                      "Проверить"
                    )}
                  </Button>
                </div>
                <div>
                  <Link onClick={handlerCheckDocument}>
                    Подписать другой документ
                  </Link>
                </div>
              </Col>
            </>
          )}
        </Row>
      )}
      <Row>
        <Col md={12}>
          {!error || (
            <p className="text-danger text-center my-0 pt-1">{error}</p>
          )}
        </Col>
      </Row>
    </>
  );
};

export default Step_3;
