import { useState } from "react";
import { Col, Form } from "react-bootstrap";
import ExtendedTemplate from "../PortalFormTemplate/ExtendedTemplate";
import ExtendedMaxTemplate from "../PortalFormTemplate/ExtendedMaxTemplate";
import TestTemplate from "../PortalFormTemplate/TestTemplate";

const Step_2 = (props) => {
  const [changTemplate, setChangeTemplate] = useState("test");

  // const handleSelect = (e) => {
  //   let val = e.target.value;
  //   setChangeTemplate(val);
  // };

  return (
   <><h5 className="text-center">Получение тестового сертификата</h5>
   <p className="text-center">Для получения тестового сертификата электронной подписи заполните форму запроса.</p>
    <Col>
      <div className="input-group mb-3">
  
      </div>

      {changTemplate === "test" ? (
        <TestTemplate path={props.path} />
      ) : changTemplate === "extended" ? (
        <ExtendedTemplate />
      ) : (
        <ExtendedMaxTemplate />
      )}
    </Col>
    </> 
  );
};

export default Step_2;
