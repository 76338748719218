import { Container, Nav, Image, Navbar, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./style.css";
import qrDownload from "../../assets/img/qrDownload.jpg";
import smartTokenGreen from "../../assets/img/smarttoken_pciconnew-300x300.png";
import { BsFillTelephoneFill, BsWalletFill } from "react-icons/bs";
const CollapsibleExample = () => {
  return (
    <div className="bg-top">
      {/*Top Navbar */}
      <Container className="py-1">
        <Row>
          <Col
            xs="12"
            lg="6"
            className="d-flex flex-column flex-lg-row  text-center"
          >
            <figure className="text-center mb-0">
              <blockquote className="blockQ mb-0">
                <BsFillTelephoneFill color="#4E4C4C" className="me-3 mt--3" />
                <a
                  className="pe-2 text-xs-center text-black"
                  href="tel:74956633034"
                  title="Секретариат"
                >
                  +7 (495) 663-30-34
                </a>
                <a
                  className="pe-2 text-xs-center text-black"
                  href="tel:74956633073"
                  title="Отдел продаж"
                >
                  +7 (495) 663-30-73
                </a>
                <a
                  className="pe-2 text-xs-center text-black"
                  href="tel:74956633093"
                  title="Техподдержка"
                >
                  +7 (495) 663-30-93
                </a>
              </blockquote>
            </figure>
          </Col>
          <Col
            xs="12"
            lg="6"
            className="d-flex flex-lg-row-reverse flex-column text-center pt-3 pt-lg-0"
          >
            <a
              className="fs14 pb-0 text-black mt--1"
              href="mailto:signal@signal-com.ru"
              title="Написать на почту"
            >
              <BsWalletFill className="me-2 " color="#4E4C4C" />
              signal@signal-com.ru
            </a>
          </Col>
        </Row>
      </Container>
      {/*End Top Navbar */}
      <Navbar
        collapseOnSelect
        expand="lg"
        bg="white"
        variant="light"
        className="border-bottom-05"
      >
 
          <Container>
       
            {/* Main */}
            <Nav className="d-flex align-items-center col-6">
              <Navbar.Text>
                <Image fluid className="img-fluid" src={smartTokenGreen} style={{ width: 60, height: 60 }}/>
              </Navbar.Text>
              <Navbar.Text>
                <Link to="https://www.smarttoken.pro" className="downloadSmart ps-2 fs-26-16">
                  SmartToken-PRO
                </Link>
              </Navbar.Text>
            </Nav>
            {/* End Main */}
           
          {/* Logo */}
       
          <div className="d-flex align-items-center d-flex justify-content-end col-6">
          <Link
            to="https://www.signal-com.ru/products/smarttoken-pro/"
            target="_blank"
            className="navbar-brand"
          ></Link>
          </div>
      
          
          {/* End Logo */}
          
        </Container>
      </Navbar>
    
    </div>
  );
};

export default CollapsibleExample;
