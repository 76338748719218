import React, { useContext, useState, useEffect } from "react";
import Context from "../../context/context";
import { gPathKeyContainer } from "../../store/constants";
import { Button } from "react-bootstrap";
import Fade from "react-bootstrap/Fade";
import Spinner from "react-bootstrap/Spinner";
import apiContext from "../../context/Api";

const GetTestCertificate = (props) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState();
  const [isLoader, setIsLoader] = useState(false);
  const [disable, setDisable] = useState(false);
  const [CertificatePem, setCertificatePem] = useState("");
  const { setStep, certificate, setCertificateList } = useContext(Context);
  const [successMsg, setSuccessMsg] = useState("");

  const {
    /** @type Api */
    api,
  } = useContext(apiContext);

  useEffect(() => {
    if (certificate) {
      setStep(3);
    }
  }, [certificate]);

  function _errorShow(error) {
    var msg = error.message;
    if (typeof msg == "undefined") msg = error;
    setOpen(!open ? !open : open);
    setError(msg);
    setIsLoader(false);
    setDisable(false);
    return;
  }

  // generateKeyPair
  const _generateKeyPair = () => {
    setIsLoader(true);
    window.scsignplugin
      .generateKeyPair(gPathKeyContainer, false)
      .then(function (dataKey) {
        createCertificate(dataKey);
      }, _errorShow);
  };

  // add Cert Attribute
  const _addCertRequestAttribute = async () => {
    // Add attribute
    await window.scsignplugin.resetCertRequestAttributes().then(
      () => console.log("attribute is clearing"),
      (err) => console.log("resetCertRequestAttributes false" + err)
    );
    const atr = [
      {
        id: "2.5.4.6",
        value: "RU",
      },
      {
        id: "2.5.4.3",
        value: props.commonName,
      },
    ];

    // Add attribute
    atr.map((attribute) => {
      window.scsignplugin
        .addCertRequestAttribute(attribute.id, attribute.value)
        .then(
          () => console.log("attr add"),
          (error) => console.log(error)
        );
    });
    _generateKeyPair();
  };

  // create sertficate
  async function createCertificate(certreq) {
    // remove headers
    certreq = certreq.replace(
      /-----BEGIN CERTIFICATE REQUEST-----|-----END CERTIFICATE REQUEST-----|\n/g,
      ""
    );

    return api.opcreatecert(certreq).then((result) => {
      const { content, status } = result;
      console.log(result);
      switch (status.code) {
        case 200:
          let derCertificate = content.certificate;

          const pemCertificate = `-----BEGIN CERTIFICATE-----\n${derCertificate
            .toString("base64")
            .match(/.{1,64}/g)
            .join("\n")}\n-----END CERTIFICATE-----`;

          setCertificatePem(pemCertificate);
          setIsLoader(false);
          setDisable(true);
          setStep(3);

          break;
        case 500:
          setOpen(!open ? !open : open);
          setIsLoader(false);
          setDisable(false);
          setError("Произошла ошибка при обращении к серверу");
          break;
        case 502:
          setOpen(!open ? !open : open);
          setIsLoader(false);
          setDisable(false);
          setError("Произошла ошибка при обращении к серверу");
          break;
        default:
          setIsLoader(false);
          setDisable(false);
          setError("Произошла ошибка при обращении к серверу");
          break;
      }
    });
  }

  const getCertificatesList = () => {
    window.scsignplugin
      .getCertificates("pkcs11:/smarttoken/", true, true)
      .then(function (data) {
        setCertificateList(data);
      }, _errorShow);
  };

  // onWriteCertificate
  useEffect(() => {
    if (!CertificatePem || !gPathKeyContainer) return;

    let isCertUser = true;
    window.scsignplugin
      .writeCertificate(
        CertificatePem,
        isCertUser ? 1 : 2,
        gPathKeyContainer + "/" + props.commonName + ".cer",
        "ASN.1"
      )
      .then(function () {
        setOpen(true);
        setSuccessMsg("Тестовый сертификат успешно записан в приложение SmartToken-PRO. Устройство готово для подписи электронных документов.");
        setDisable(true);
        getCertificatesList();
      }, _errorShow);
  }, [CertificatePem]);

  function generateCMC() {
    setError("");
    setSuccessMsg("");
    _addCertRequestAttribute();
  }

  return (
    <>
    <div className="d-grid gap-2  justify-content-center">
      <Button
        className="mt-4"
        disabled={disable}
        onClick={() => generateCMC()}
        aria-controls="example-fade-text"
        aria-expanded={open}
        variant="secondary"
        size="lg"
        style={{ minWidth: "350px" }}
      >
        {isLoader ? (
          <>
            <span className="d-inline">Запрос на сертификат</span>
            <Spinner
              className="ms-3"
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          </>
        ) : (
          props.text
        )}
      </Button>
   
    </div>
   
       <Fade in={open}>
        <div id="example-fade-text">
          
          {successMsg ? (
            <p className="text-center text-success pt-2">{successMsg}</p>
          ) : (
            ""
          )}
          {error ? <p className="text-center text-danger pt-2">{error}</p> : ""}
        </div>
      </Fade>
    </>
  );
};

export default GetTestCertificate;
