import React from "react";
import Api from "../services/Api";

const Context = React.createContext();

const api = new Api();

export function ApiProvider(props) {
  const { children } = props;
  return <Context.Provider value={{ api }}>{children}</Context.Provider>;
}

export default Context;
