import React from "react";
import { Button, Modal } from "react-bootstrap";

const ModalMy = ({ showModal, closeModal, version }) => {
 
  return (
    <Modal show={showModal} onHide={closeModal}>
      <Modal.Header className="pt-4" closeButton></Modal.Header>
      <Modal.Body>{version}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={closeModal}>
          Закрыть
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalMy;
