import { useContext, useEffect, useState } from "react";

import Context from "../../../context/context";
import { BsUnlockFill, BsLockFill } from "react-icons/bs";
import { Tab, Row, ListGroup, Col, Spinner } from "react-bootstrap";

import Step_1 from "./Steps/Step_1";
import Step_2 from "./Steps/Step_2";
import Step_3 from "./Steps/Step_3";

import "../style.css";
const PortalForm = () => {
  const { step, setStep, path, setResetTabStep, waitingStep3, setWaitCertList } = useContext(Context);
  const [isLoader1, setIsLoader1] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isDisabled1, setIsDisabled1] = useState(true);

  const [tabInit, setTabInit] = useState(false);
  const [tabGetCert, setTabGetCert] = useState(false);
  const [tabSig, setTabSig] = useState(false);
 

  const activeTab = (e) => {
    if(e.target.id === "list-tab-#step1") {
      setResetTabStep(true)
      setTabInit(true)
      setTabGetCert(false)
      setTabSig(false)
    }
    if(e.target.id === "list-tab-#step2") {
      setResetTabStep(true)
      setTabInit(false)
      setTabGetCert(true)
      setTabSig(false)
    }
    if(e.target.id === "list-tab-#step3") {
      setResetTabStep(true)
      setTabInit(false)
      setTabGetCert(false)
      setTabSig(true)
    }
  }

 
  useEffect(() => {
    if (isDisabled && step === 1) {
      setIsLoader1(false);
      setTabInit(true)
      setTabGetCert(false)
      setTabSig(false)
    }
   
    if (step === 2 && waitingStep3) {
      setIsLoader1(false);
      setIsDisabled(false);
      setTabInit(false)
      setTabGetCert(true)
      setTabSig(false)
    }
    if (step === 3) {
      setIsDisabled(false);
      setTabInit(false)
      setTabGetCert(true)
      setTabSig(false)
      setIsDisabled1(false);
      setIsLoader1(false);
      setStep(4);
    }
    if (step === 4) {
      
      setIsLoader1(false);
      setTabInit(false)
      setTabGetCert(false)
      setTabSig(true)
      setWaitCertList(false)
    }
  }, [step, waitingStep3]);

  return (
    <Tab.Container
      id="list"
      defaultActiveKey="#step1"
      className="mt-5"
    >
      <Row className="border mt-5">
        <Col lg={4} className="px-0 bg-light py-5">
          <ListGroup variant="flush">
            <ListGroup.Item
              onClick={(e) => activeTab(e)}
              active={tabInit}
              action
              href="#step1"
              variant="secondary"
              className="my-1 py-3"
            >
              Подготовка работы
            </ListGroup.Item>
            <ListGroup.Item
              onClick={(e) => activeTab(e)}
              active={tabGetCert}
              action
              disabled={isDisabled}
              href="#step2"
              variant="secondary"
              className="my-1 py-3  "
            >
              Получение тестового сертификата
              {isDisabled ? (
                <BsLockFill className="mt-1 float-end" />
              ) : (
                <BsUnlockFill className="mt-1  float-end" />
              )}
            </ListGroup.Item>
            <ListGroup.Item
            onClick={(e) => activeTab(e)}
              active={tabSig}
              action
              disabled={isDisabled1}
              href="#step3"
              variant="secondary"
              className="my-1 py-3"
            >
              Подпись документов
              {isDisabled1 ? (
                <BsLockFill className="mt-1 float-end" />
              ) : (
                <BsUnlockFill className="mt-1  float-end" />
              )}
              {!isLoader1 || (
                <div className="badge float-end">
                  <Spinner
                    className="text-secondary"
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                </div>
              )}
            </ListGroup.Item>
    
          </ListGroup>
        </Col>
        <Col
          lg={8}
          className=" 
          py-5"
        >
          <Tab.Content>
            <Tab.Pane eventKey="#step1"  active={tabInit}>
              {/* step 1 */}
              <Step_1 />
            </Tab.Pane>
            <Tab.Pane eventKey="#step2"  active={tabGetCert}>
              {/* step 2 */}
              <Step_2 path={path} />
            </Tab.Pane>
            <Tab.Pane eventKey="#step3"  active={tabSig}>
              {/* step 3*/}
              <Step_3 path={path} />
            </Tab.Pane>
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
  );
};

export default PortalForm;
