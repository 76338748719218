import {
  Dropdown,
  DropdownButton,
  Button,
} from "react-bootstrap";

const ExtendedTemplate = () => {
return (
<>
<div className="input-group  mb-3">
  <span
    className="input-group-text"
    id="inputGroup-sizing-default"
  >
    CN:
  </span>
  <input
    type="text"
    className="form-control"
    aria-label="Sizing example input"
    aria-describedby="inputGroup-sizing-default"
    defaultValue="Тестовый сертификат SmartToken-PRO"
  />
</div>
<div className="input-group  mb-3">
  <span
    className="input-group-text"
    id="inputGroup-sizing-default"
  >
    SN:
  </span>
  <input
    type="text"
    className="form-control"
    aria-label="Sizing example input"
    aria-describedby="inputGroup-sizing-default"
    defaultValue="Тестовый сертификат SmartToken-PRO"
  />
</div>
<div className="input-group  mb-3">
  <span
    className="input-group-text"
    id="inputGroup-sizing-default"
  >
    GN:
  </span>
  <input
    type="text"
    className="form-control"
    aria-label="Sizing example input"
    aria-describedby="inputGroup-sizing-default"
    defaultValue="Тестовый сертификат SmartToken-PRO"
  />
</div>
<div className="input-group  mb-3">
  <span
    className="input-group-text portal-center-indicator"
    id="inputGroup-sizing-default"
  >
    E:
  </span>
  <input
    type="text"
    className="form-control"
    aria-label="Sizing example input"
    aria-describedby="inputGroup-sizing-default"
    defaultValue="Тестовый сертификат SmartToken-PRO"
  />
</div>
<div className="input-group  mb-3">
  <span
    className="input-group-text portal-center-indicator"
    id="inputGroup-sizing-default"
  >
    O:
  </span>
  <input
    type="text"
    className="form-control"
    aria-label="Sizing example input"
    aria-describedby="inputGroup-sizing-default"
    defaultValue="Тестовый сертификат SmartToken-PRO"
  />
</div>
<div className="input-group  mb-3">
  <span
    className="input-group-text portal-center-indicator"
    id="inputGroup-sizing-default"
  >
    L:
  </span>
  <input
    type="text"
    className="form-control"
    aria-label="Sizing example input"
    aria-describedby="inputGroup-sizing-default"
    defaultValue="Тестовый сертификат SmartToken-PRO"
  />
</div>
<div className="input-group  mb-3">
  <span
    className="input-group-text"
    id="inputGroup-sizing-default"
  >
    OU:
  </span>
  <input
    type="text"
    className="form-control"
    aria-label="Sizing example input"
    aria-describedby="inputGroup-sizing-default"
    defaultValue="Тестовый сертификат SmartToken-PRO"
  />
</div>
<div className="input-group  mb-3">
  <span
    className="input-group-text portal-center-indicator"
    id="inputGroup-sizing-default"
  >
    T:
  </span>
  <input
    type="text"
    className="form-control"
    aria-label="Sizing example input"
    aria-describedby="inputGroup-sizing-default"
    defaultValue="Тестовый сертификат SmartToken-PRO"
  />
</div>
<div className="input-group  mb-3">
  <span
    className="input-group-text portal-center-indicator"
    id="inputGroup-sizing-default"
  >
    E:
  </span>
  <input
    type="text"
    className="form-control"
    aria-label="Sizing example input"
    aria-describedby="inputGroup-sizing-default"
    defaultValue="Тестовый сертификат SmartToken-PRO"
  />
</div>
<div className="input-group mb-3">
  <DropdownButton
    id={`dropdown-button-drop-down`}
    drop="down"
    variant="secondary"
    //title={initButton}
   // onSelect={handleToken}
  >
    <Dropdown.Item
      eventKey="Something else here"
      // disabled={
      //   initButton === "Something else here" ? true : false
      // }
    >
      Something else here
    </Dropdown.Item>
    <Dropdown.Divider />
    <Dropdown.Item
      eventKey="SmartToken-PRO"
     // disabled={
       // initButton === "SmartToken-PRO" ? true : false
    //  }
    >
      SmartToken-PRO
    </Dropdown.Item>
  </DropdownButton>

  <input
    type="text"
    className="form-control"
   // defaultValue={file ? `${file.name}` : ""}
   // onClick={handleUploadClick}
  />
  <input
    //onChange={handleFileChange}
    style={{ display: "none" }}
    type="file"
    className="form-control"
    // style={{visibility:'hidden'}}
    id="inputGroupFile02"
  />
  <label
    className="input-group-text"
    htmlFor="inputGroupFile02"
  >
    Обзор...
  </label>
</div>
<div className="d-grid gap-2  justify-content-center">
  <Button
    variant="secondary"
    size="lg"
    style={{ minWidth: "350px" }}
  >
    Получить сертификат
  </Button>
</div>
</>
)
}

export default ExtendedTemplate

