import React, { useState } from "react";
import { detect } from "detect-browser";
import { Button } from "react-bootstrap";
import ModalMy from "../components/ModalMy";
import { Link } from "react-router-dom";
const InstallExtension = () => {
  const browser = detect();
  let url = "/";
  const [modalShow, setModalShow] = useState(false);

  const reloadPage = () => {
    window.location.reload();
  };

  // handle the case where we don't detect the browser
  switch (browser && browser.name) {
    case "chrome":
      url =
        "https://chrome.google.com/webstore/detail/signal-com-signature-plug/ceifjolbdjihdddppedlcocpgckafcak";
      break;
    case "firefox":
      url = "https://www.e-notary.ru/files/download/esp/esp@signal-com.ru.xpi";
      break;
    case "yandexbrowser":
      url =
        "https://chrome.google.com/webstore/detail/signal-com-signature-plug/ceifjolbdjihdddppedlcocpgckafcak";
      break;
    case "edge":
      break;

    default:
      console.log(browser.name);
      console.log("not supported");
  }

  // window open
  const hendlerOpenWindow = () => {
    setModalShow(true);
    const height = window.screen.availHeight;
    const width = 900;
    const top = 0;
    const left =
      window.screen.availLeft + (window.screen.availWidth - width) / 2;
    const myWindow = window.open(
      url,
      "_blank",
      "location=yes,height=" +
        height +
        ",width=" +
        width +
        ",top=" +
        top +
        ",left=" +
        left +
        ",scrollbars=yes,status=yes"
    );

    var timer = setInterval(function () {
      if (myWindow.closed) {
        clearInterval(timer);
        setModalShow(false);
        reloadPage();
      }
    }, 1500);
  };

  const handleClose = () => {
    console.log("окно не закрыто");
  };

  return (
    <>
      <div className="pt-2 text-center text-lg-start">
        <label className="custom-control-label" htmlFor="extension">
          Шаг 1
        </label>
        {browser.name === "firefox" ? (
          <>
            <Link
              className="text-success ps-3 text-decoration-underline pt-0"
              to={url}
              download
            >
              Установите и активируйте расширение для браузера
            </Link>
            <p>
              После активации расширения обновите страницу (кнопка обновить)
            </p>
          </>
        ) : (
          <>
            <Button
              className="text-success ps-3  pt-0"
              onClick={() => hendlerOpenWindow()}
              variant="link"
              id="extension"
              name="extension"
            >
              Установите и активируйте расширение для браузера
            </Button>
            <br />
            <div className="mt-3  mb-2  text-dark text-center">
              <span>
                После активации расширения обновите страницу{" "}
                <Button
                  size="sm"
                  onClick={() => window.location.reload()}
                  variant="outline-success"
                  id="extensionUpdate"
                  name="extensionUpdate"
                  className="ms-2"
                >
                  Обновить
                </Button>
              </span>
            </div>
          </>
        )}

        <ModalMy show={modalShow} onHide={() => handleClose()} />
      </div>
    </>
  );
};
export default InstallExtension;
