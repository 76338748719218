import React from "react";
import Spinner from 'react-bootstrap/Spinner';
const Loader = () => {
  return (
  <div className="d-flex justify-content-center">
  <Spinner
    as="span"
    animation="grow"
    size="sm"
    role="status"
    aria-hidden="true"
  /> 
  </div>
)
}
export default Loader;