import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./assets/css/App.css";
import React from "react";
import Main from "./components/Pages/Main";

function App() {

  return (
    <div className="App">
      <Main />
    </div>
  )
}

export default App
