/*
 * scsignplugin JavaScript Library
 * http://www.signal-com.ru
 * Copyright (C) 2023 Signal-COM
 * $Id: scsignplugin.js 17609 2016-12-22 13:10:26Z
 */
 
(function() {
  var helper;
  var pluginLoaded = false;
  var mimetype = "application/x-esp";
  var extId = "ceifjolbdjihdddppedlcocpgckafcak";
  var defaultCheckTimeout = 3000;
  window["extensionLink_psign"] = "https://chrome.google.com/webstore/detail/signal-com-signature-plug/" + extId;
  window["scsignplugin_checkLoad_timeout"] = defaultCheckTimeout;

  function detectBrowsers() {
      var ua = navigator.userAgent;
      var result = { opera:false, firefox:false, safari:false, msie:false, msedge:false, chrome:false, blink:false, shortVersion:0, fullVersion:0 };
      // Opera 8.0+
      result.opera = (!!window.opr && !!window.opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
 
      // Firefox 1.0+
      result.firefox = typeof InstallTrigger !== 'undefined' || ua.match(/firefox|fxios/i) !== null;
      // At least Safari 3+: "[object HTMLElementConstructor]"
      result.safari = Object.prototype.toString.call(window.HTMLElement).indexOf('Constructor') > 0;
      // Internet Explorer 6-11
      result.msie = /*@cc_on!@*/false || !!document.documentMode;
      // Edge 20+
      result.msedge = !result.msie && !!window.StyleMedia;
      // Chrome 1+
      result.chrome = !!window.chrome && !!window.chrome.webstore;
      // Chrome 71+
      if(!result.chrome) result.chrome = !!window.chrome && (ua.indexOf("Chrome/") !== -1);
      // Blink engine detection
      result.blink = (result.chrome || result.opera) && !!window.CSS;
      
      if( result.opera ){
          if(ua.indexOf("Version/") !== -1){
              result.fullVersion = ua.split("Version/")[1];
          }else if(ua.indexOf("OPR/") !== -1){
              result.fullVersion = ua.split("OPR/")[1];
          }else{
              result.fullVersion = 0;
          }
      }else if(result.firefox){
          extId = "esp@signal-com.ru"
          window["extensionLink_psign"] = "https://www.e-notary.ru/files/download/esp/esp@signal-com.ru.xpi"
          if(ua.indexOf("Firefox/") !== -1){
              result.fullVersion = ua.split("Firefox/")[1];
          }else{
              result.fullVersion = 0;
          }
      }else if(result.safari){
          if(ua.indexOf("Version/") !== -1){
              result.fullVersion = (ua.split("Version/")[1]).split(" ")[0];
          }else{
              result.fullVersion = 0;
          }           
      }else if(result.msie){
          if(ua.indexOf("MSIE ") !== -1){
              result.fullVersion = (ua.split("MSIE ")[1]).split(";")[0];
          }else if(ua.indexOf("; rv:") !== -1){
              result.fullVersion = (ua.split("; rv:")[1]).split(")")[0];
          }else{
              result.fullVersion = 0;
          }
      }else if(result.msedge){
          if(ua.indexOf("Edge") !== -1){
              result.fullVersion = (ua.split("Edge")[1]).split("/")[1];
          }else{
              result.fullVersion = 0;
          }
      }else if(result.chrome){
          if(ua.indexOf("Chrome/") !== -1){
              result.fullVersion = (ua.split("Chrome/")[1]).split(" ")[0];
          }else{
              result.fullVersion = 0;
          }
      }
      
      if(result.fullVersion !== 0){
          result.shortVersion = result.fullVersion.split(".")[0];
      }else{
          result.shortVersion = 0;
      }
      
      return result;
  }

  function initPlugin() {
      //Create a var that basically saves a random number to guarantee an unique identifier for a function. _plugin_ can be any other name.
      var callbackFn = "_plugin_" + Math.floor(Math.random() * 100000000);

      window[callbackFn] = function(data) {
          window["scsignplugin_extension_loaded"] = true;            
          logD("Extension loaded");
          window.postMessage({
              source: 'scsignplugin',
              state_event:"ExtensionLoaded"
          }, "*");
          //Retrieve the wyrmhole factory for later creation
          helper = data.wyrmhole;

          window.scsignpluginLoad = createPlugin;
          if (!window["scsignplugin_autoLoad_disabled"]) {
              createPlugin();
          }
      };
      
      /*Post a message to the extension, telling it to instantiate a a wyrmhole.
       FBDevTeam should be the name of your company inside the plugin configuration. For the echoTestPlugin its FBDevTeam.
       callbackFn is the function that will be called once the result of the postMessage is returned.
       */
      window.postMessage({
          firebreath: 'ru.signalcom.esp',
          callback: callbackFn
      }, "*");
  }

  function createPlugin() {
      if (helper === undefined) {
          logD("Extension was not initialized");
          return;
      }
      if (pluginLoaded == true) {
          logD("Plugin was already loaded");
          return;
      }
      logD("Start scsignplugin loading");
      pluginLoaded = undefined;
      //Using the wyrmholeFactory we create a wyrmhole.
      helper.create(mimetype).then(
              function(wyrmhole) {
                  //With the created wyrmhole we instantiate a new FireWyrmJS object that will allow us to create the plugin.
                  var FWJS = window.FireWyrmJS;
                  //Create pluginFactory that will allow the plugin creation.
                  window.pluginFactory = new FWJS(wyrmhole);
                  window.pluginFactory.create(mimetype, {/*some params*/}).then(
                          function(pluginObj) {
                              if (pluginLoaded == true) {
                                  logD("Plugin was previously loaded");
                                  return;
                              }

                              scsignpluginLoaded(pluginObj);
                          },
                          function(error) {
                              console.log("An Unexpected Error has ocurred: ", error);
                          }
                  );
              },
              function(error) {
                  console.log("An Unexpected Error during plugin load has ocurred: ", error);
              }
      );
      var checkTimeout = window["scsignplugin_checkLoad_timeout"];
      if (checkTimeout === undefined || !Number.isInteger(checkTimeout)) {
          checkTimeout = defaultCheckTimeout;
      }
      setTimeout(checkPluginLoading, checkTimeout);
  }

  function checkPluginLoading() {
      if (pluginLoaded === undefined) {
          logD("Extension not responded (unloaded)");
          // content-script instantly sends message "Disconnected" or "success" 
          // if extension port is able to connect (extension loaded)
          // So if there was no such messages in timeout period, assume that extension was unloaded
          window.postMessage({
              source: 'scsignplugin',
              state_event:"ExtensionUnloaded"
          }, "*");
          return;
      }
      if (pluginLoaded == false) {
          logD("Plugin was not loaded");
          if (!window["scsignplugin_autoLoad_disabled"]) {                
              logD("Try reload plugin");
              createPlugin();
          }
      }
      
  }

  function scsignpluginLoaded(pluginObj) {
      //Save the plugin to a global var for later access
      window.scsignplugin = pluginObj;
      logD("Plugin loaded");
      pluginLoaded = true;
      window.postMessage({
          source: 'scsignplugin',
          state_event:"PluginLoaded"
      }, "*");
  }

  window.scsignpluginLoaded = scsignpluginLoaded;

  const cbInitPlugin = function(event) {
      logD("Start initialising scsignplugin extension");
      var browser = detectBrowsers();
      if (browser.chrome || browser.opera || (browser.firefox && browser.shortVersion >= 52) ) {
          initPlugin();
      } else if (browser.msie) {
          if (window.ActiveXObject) {
              scsignpluginLoaded(new window.ActiveXObject("SignalCOM.Esp"));
          } else {
              var objEsp = '<object type="application/x-esp"><param name="onload" value="scsignpluginLoaded"/></object>';
              window.document.body.insertAdjacentHTML('beforeEnd', objEsp);
          }
      } else {
          var objEsp = '<object type="application/x-esp"><param name="onload" value="scsignpluginLoaded"/></object>';
          window.document.body.insertAdjacentHTML('beforeEnd', objEsp);
      }
  }

  if (window.addEventListener) {
      window.addEventListener("load", cbInitPlugin, false);
      window.addEventListener("message", function(event) {
          if (event.source != window) { return; }

          if (event.data && event.data.ext == extId && event.data.source && event.data.source == "host") {
              if (event.data.error && event.data.error == "Disconnected") {
                  //Disconnected extension, not connected to plugin
                  pluginLoaded = false;
                  logD("Disconnected extension, not connected to plugin");
                  window.postMessage({
                          source: 'scsignplugin',
                          state_event:"PluginUnloaded"
                      }, "*");
              } else if (event.data.message && event.data.message == "Disconnected") {
                  //Disconnected extension after successfull connect to plugin
                  logD("Disconnected extension port");
                  if (pluginLoaded == true) {
                      logD("Extension unloaded");
                      window.postMessage({
                          source: 'scsignplugin',
                          state_event:"ExtensionUnloaded"
                      }, "*");
                  }
                  pluginLoaded = false;
              } else if (event.data.status && event.data.status == "success") {
                  //Successfull connect to plugin
              }
          }

      });
      
  } else {
      window.attachEvent("onload", cbInitPlugin);
  }

  function logD(message) {
      if (window["scsignplugin_log_debug_loadscript"]) {
          console.debug(message);
      }
  }
})();
