import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import ErrorPage from "./components/error-page";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import App from "./App";
// import DownloadApp from "./components/Pages/DownloadApp";
import Portal from "./components/Pages/Portal";
// import PrivacyPolicy from "./components/Pages/PrivacyPolicy";
// import Manual from "./components/Pages/Manual";
// import FormIframe from "./components/Pages/chanks/FormIframe";
import reportWebVitals from "./reportWebVitals";
import store from "./store";
import "./index.css";

const router = createBrowserRouter(
  [
    // {
    //   path: "/",
    //   element: <App />,
    //   errorElement: <ErrorPage />,
    // },
    {
      path: "/",
      element: <Portal />,
      errorElement: <ErrorPage />,
    },
    // {
    //   path: "/download-app",
    //   element: <DownloadApp />,
    // },
   
    // {
    //   path: "/portal",
    //   element: <Portal />,  // перед билдом закомментить 
    // },
    // {
    //   path: "/privacy-policy",
    //   element: <PrivacyPolicy />,
    // },
    // {
    //   path: "/manual",
    //   element: <Manual />,
    // },
    // {
    //   path: "/form",
    //   element: <FormIframe />,
    // },
  ],
  {
    basename: "/portal/",
  }
);

ReactDOM.createRoot(document.getElementById("root")).render(
  <Provider store={store}>
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  </Provider>
);

reportWebVitals();