import {
  Dropdown,
  DropdownButton,
  Button,
  Form,
} from "react-bootstrap";

const ExtendedMaxTemplate = () => {
return (
  <div>
  <h5 style={{ color: "#196895" }}>
    Сведения о владельце сертификата
  </h5>

  <Form>
    <Form.Group className="mb-1" controlId="formBasicEmail">
      <Form.Control type="email" placeholder="Полное имя" />
    </Form.Group>

    <Form.Group className="mb-1" controlId="formBasicEmail">
      <Form.Control type="email" placeholder="Фамилия" />
    </Form.Group>

    <Form.Group className="mb-1" controlId="formBasicEmail">
      <Form.Control type="email" placeholder="Отчество" />
    </Form.Group>

    <Form.Group className="mb-1" controlId="formBasicEmail">
      <Form.Control type="email" placeholder="Организация" />
    </Form.Group>

    <Form.Group className="mb-1" controlId="formBasicEmail">
      <Form.Control
        type="email"
        placeholder="Подразделение"
      />
    </Form.Group>

    <Form.Group className="mb-1" controlId="formBasicEmail">
      <Form.Control type="email" placeholder="Должность" />
    </Form.Group>

    <Form.Group className="mb-1" controlId="formBasicEmail">
      <Form.Control type="email" placeholder="ИНН" />
    </Form.Group>

    <Form.Group className="mb-1" controlId="formBasicEmail">
      <Form.Control type="email" placeholder="ИНН ЮЛ" />
    </Form.Group>

    <Form.Group className="mb-1" controlId="formBasicEmail">
      <Form.Control type="email" placeholder="ОГРН" />
    </Form.Group>

    <Form.Group className="mb-1" controlId="formBasicEmail">
      <Form.Control type="email" placeholder="СНИЛС" />
    </Form.Group>

    <Form.Group className="mb-1" controlId="formBasicEmail">
      <Form.Control
        type="email"
        placeholder="Адрес электронной почты"
      />
    </Form.Group>

    <Form.Group className="mb-1" controlId="formBasicEmail">
      <Form.Control type="email" placeholder="Страна" />
    </Form.Group>

    <Form.Group className="mb-1" controlId="formBasicEmail">
      <Form.Control type="email" placeholder="Область" />
    </Form.Group>

    <Form.Group className="mb-1" controlId="formBasicEmail">
      <Form.Control type="email" placeholder="Город" />
    </Form.Group>

    <Form.Group className="mb-1" controlId="formBasicEmail">
      <Form.Control
        type="email"
        placeholder="Название улицы, номер дома"
      />
    </Form.Group>

    <Form.Group className="mb-1" controlId="formBasicEmail">
      <Form.Control
        type="email"
        placeholder="Структурированное имя"
      />
    </Form.Group>

    <Form.Group className="mb-1" controlId="formBasicEmail">
      <Form.Control type="email" placeholder="Псвевдоним" />
    </Form.Group>

    <Form.Group className="mb-1" controlId="formBasicEmail">
      <Form.Control
        type="email"
        placeholder="Наименование средства ЭП"
      />
    </Form.Group>
    <Form.Group className="mb-1" controlId="formBasicEmail">
      <Form.Control
        type="email"
        placeholder="Класс средства ЭП"
      />
    </Form.Group>
  </Form>
  <p className="pt-2 text-decoration-underline">
    Алгоритм: ГОСТ Р 34.10-2012, 256 бит
  </p>
  <div className="input-group mb-3">
    <DropdownButton
      id={`dropdown-button-drop-down`}
      drop="down"
      variant="secondary"
      //title={initButton}
      //onSelect={handleToken}
    >
      <Dropdown.Item
        eventKey="Something else here"
       // disabled={
       //   initButton === "Something else here" ? true : false
      //  }
      >
        Something else here
      </Dropdown.Item>
      <Dropdown.Divider />
      <Dropdown.Item
        eventKey="SmartToken-PRO"
      //  disabled={
       //   initButton === "SmartToken-PRO" ? true : false
       // }
      >
        SmartToken-PRO
      </Dropdown.Item>
    </DropdownButton>

    <input
      type="text"
      className="form-control"
      //defaultValue={file ? `${file.name}` : ""}
     // onClick={handleUploadClick}
    />
    <input
      //onChange={handleFileChange}
      style={{ display: "none" }}
      type="file"
      className="form-control"
      // style={{visibility:'hidden'}}
      id="inputGroupFile02"
    />
    <label
      className="input-group-text"
      htmlFor="inputGroupFile02"
    >
      Обзор...
    </label>
  </div>
  <div className="d-grid gap-2  justify-content-center">
    <Button
      variant="secondary"
      size="lg"
      style={{ minWidth: "350px" }}
    >
      Получить сертификат
    </Button>
  </div>
</div>
)
}

export default ExtendedMaxTemplate

