import { createSlice } from '@reduxjs/toolkit'
 
export const plaginFnSlice = createSlice({
  name: 'pluginFn',
  initialState: 0,
  reducers: {
    onDevicePresent1: (state) => {
      // Check token on device
    }, 
  },
})

// Action creators are generated for each case reducer function
export const { onDevicePresent1 } = plaginFnSlice.actions

export default plaginFnSlice.reducer
  
  
  
  
  
