import React, { useState, useEffect, useContext } from "react";
import "../common/scsignplugin.js";
import Loader from "../components/Loader";
import InstallPlagin from "../helpers/InstallPlagin";
import Context from "../context/context";
import { Button, Alert } from "react-bootstrap";
import ModalMy from "../components/ModalMy/";
import InstallExtension from "../helpers/InstallExtension";
const InitPlugin = () => {
  const {
    setStep,
    path,
    setCertificateList,
    setWaitingStep3,
    waitCertList,
    setWaitCertList,
  } = useContext(Context);
  const [isLoader, setIsLoader] = useState(true);
  const [isInitPlagin, setInitPlagin] = useState(false);
  const [isInitExtension, setInitExtension] = useState(false);
  const [isToken, setIsToken] = useState({ is: false });
  const [isKeyContainer, setIsKeyContainer] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [version, setVersion] = useState("");

// Check plagin, extension
  useEffect(() => {
    const checkPlagin = setInterval(function () {
      if (window.scsignplugin) {
        initPlugin();
      }
      if (localStorage.getItem("plagin") === "Host disconnected") {
        setInitPlagin(false);
        setIsLoader(false);
        setInitExtension(true);
      } else {
        setInitExtension(false);
        setInitPlagin(true);
        setIsLoader(false);
      }
    }, 1000);

    const initPlugin = async () => {
      await window.scsignplugin.initPlugin().then(
        function () {
          console.log("init plagin");
          clearInterval(checkPlagin);
          setInitPlagin(true);
          if (window["scsignplugin_extension_loaded"] ) {
            setInitExtension(true);
          }
          setIsLoader(false);
        },
        function (error) {
          _errorShow(error);
          setInitPlagin(false);
          //setStep(1);
        }
      );
    };
  }, []);

  // Check token on device
  useEffect(() => {
    const checkDevice = setInterval(function () {
      if (isInitExtension && isInitPlagin) {
        onDevicePresent();
      }
    }, 1000);

    const onDevicePresent = async () => {
      await window.scsignplugin.devicePresent(path).then(function (isExists) {
        if (isExists) {
          clearInterval(checkDevice);
          return setIsToken({
            is: true,
            text: "Проверка подключения SmartToken-PRO - Ок",
          });
        } else {
          return setIsToken({
            is: false,
            text: "Подключите SmartToken-PRO к ПК и подтвердите разрешение на доступ.",
          });
        }
      }, _errorShow);
    };
  }, [isInitPlagin, isInitExtension, path]);

  useEffect(() => {
    const getCertificatesList = () => {
      window.scsignplugin
        .getCertificates("pkcs11:/smarttoken/", true, true)
        .then(function (data) {
          if (data.length) {
            setCertificateList(data);
          } else {
            setCertificateList(data);
            setWaitingStep3(true);
            setWaitCertList(false);
          }
        }, _errorShow);
    };

    //Check for a key container
    function onKeyContainerExists() {
      if (isToken.is) {
        window.scsignplugin.keyContainerExists(path).then(function (isExists) {
          if (isExists) {
            setStep(2);
            getCertificatesList();
            return setIsKeyContainer("ok");
          } else {
            return setIsKeyContainer("err");
          }
        }, _errorShow);
      }
    }
    onKeyContainerExists();
  }, [
    path,
    setStep,
    isToken,
    setCertificateList,
    setWaitingStep3,
    setWaitCertList,
  ]);

  // Get plugin version
  function Version() {
    window.scsignplugin.getVersion().then(function (v) {
      var msg = "Версия плагина: " + v;
      setVersion(msg);
    }, _errorShow);
  }

  const handleOpenModal = () => {
    Version();
    setShowModal(true);
  };
  const handleCloseModal = () => setShowModal(false);

  function _errorShow(error) {
    var msg = error.message;
    if (typeof msg == "undefined") msg = error;
    alert(msg);
  }

  return (
    <>
      <div className="text-center">
        <h5 className="text-center">Подготовка работы</h5>
        <p>
          Для начала работы последовательно установите расширение для браузера,
          криптоплагин и подключите SmartToken-PRO к ПК
        </p>
        <p className="pt-2 pb-3">
          (рекомендуемые браузеры для работы: Chrome, FireFox, Yandex).
        </p>
      </div>
      {isLoader ? (
        <Loader />
      ) : !isInitExtension ? (
        <div className="row justify-content-center">
          <div className="col-12 row justify-content-center">
            <Alert variant="warning" className="pt-2 w-75 ">
              <InstallExtension />
            </Alert>
          </div>
        </div>
      ) : !isInitPlagin ? (
        <div className="row justify-content-center">
          <div className="col-12 row justify-content-center">
            <Alert variant="warning" className="pt-2 w-75 ">
              <InstallPlagin />
            </Alert>
          </div>
        </div>
      ) : (
        <>
          <div className="row justify-content-center">
            <div className="col-12 row justify-content-center">
              <Alert variant="success" className=" w-75 ">
                <div className="position-relative ">
                  <div>
                    {!waitCertList ? (
                      <>
                        <Button
                          variant="link"
                          className="position-absolute top-end--15"
                          onClick={handleOpenModal}
                        >
                          Версия плагина
                        </Button>
                        <ModalMy
                          showModal={showModal}
                          closeModal={handleCloseModal}
                          version={version}
                        />
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <p className="pt-3">Проверка установки расширения - Ок</p>
                <p>Проверка установки криптоплагина - Ок</p>
 
                <p className={isToken.is ? "pt-0" : "text-danger pt-0"}>
                  {isToken.text || (
                    <span
                      className="spinner-grow spinner-grow-sm text-secondary"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                </p>

                {isKeyContainer === "err" ? (
                  <p className="text-danger">
                    Доступ к закрытой области токена отклонен!
                  </p>
                ) : (
                  ""
                )}

                {isToken.is && waitCertList && isKeyContainer === "ok" ? (
                  <p>
                    Подготовка к работе, пожалуйста подождите{" "}
                    <span
                      className="spinner-grow spinner-grow-sm text-secondary"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  </p>
                ) : (
                  ""
                )}
              </Alert>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default InitPlugin;
