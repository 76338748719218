import { useState, useEffect } from "react";

const PlatformDetector = () => {
  const [platform, setPlatform] = useState("");

  useEffect(() => {
    const userAgent = window.navigator.userAgent;
    if (userAgent.includes("Mac")) {
      setPlatform("MacOS");
    } else if (userAgent.includes("Red Hat")) {
      setPlatform("Red Hat");
    } else if (userAgent.includes("Debian")) {
      setPlatform("Debian");
    } else if (userAgent.includes("Win")) {
      setPlatform("Windows");
    } else {
      setPlatform("Unknown");
    }
  }, []);
  return platform;
};

export default PlatformDetector;
