import React  from "react";
import { Button } from "react-bootstrap";
import { saveAs } from "file-saver";
import PlatformDetector from "./PlatformDetector";

const InstallPlagin = () => {
  const OS = PlatformDetector();

  const windowsFile = () => {
    saveAs(
      "https://smarttoken.pro/portal/download/psign_distros/scsignp_ru.msi",
      "scsignp_ru.msi"
    );
  };

  const macOSFile = () => {
    const link = document.createElement("a");
    link.href =
      "https://smarttoken.pro/portal/download/psign_distros/scsignp.pkg";
    link.download = "scsignp.pkg";
    link.click();
  };

  const debianFile = () => {
    saveAs(
      "https://smarttoken.pro/portal/download/psign_distros/scsignp-1.3.0.3.x86_64.deb",
      "scsignp-1.3.0.3.x86_64.deb"
    );
  };

  const redHatFile = () => {
    saveAs(
      "https://smarttoken.pro/portal/download/psign_distros/scsignp-1.3.0.3.x86_64.rpm",
      "scsignp-1.3.0.3.x86_64.rpm"
    );
  };

  const handeleGetPlagin = () => {
    switch (OS) {
      case "Windows":
        windowsFile();
        break;
      case "MacOS":
        macOSFile();
        break;
      case "Debian":
        debianFile();
        break;
      case "Red Hat":
        redHatFile();
        break;
      default:
        alert("Нет плагина для Вашей ОС " + OS);
    }
  };

  return (
    <div className="pt-2 text-center text-lg-start">
        <Button
          className="text-success  pt-0 px-0 text-decoration-none"
          onClick={handeleGetPlagin}
          variant="link"
          id="plugin"
          name="plugin"
        > 
           {OS === "MacOS"? ( <>
         
           <span className="alertText">Шаг 2</span>
           <span className=" ps-3 text-decoration-underline">Скачайте и установите криптоплагин SC Signature Plug-in и подтвердите разрешение для доступа.</span>
           <br/><br/><span className="text-dark ">На MacOS при установке плагина SC Signature Plug-in в разделе «Выбор места установки» необходимо указать «Установить только для меня».</span>
           </> ) :  
          ( <>
            <span className="alertText">Шаг 2</span>
            <span className=" ps-3 text-decoration-underline">Установите криптоплагин SC Signature Plug-in и подтвердите разрешение</span>
            </> )
           } 
           
        </Button>
      </div>
 
  );
};
export default InstallPlagin;
