import { useContext, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Context from "../../../../context/context";
import GetTestCertificate from "../../../GetTestCertificate";
import GetCertificateName from "../../../../helpers/GetCertificateName";

const TestTemplate = (props) => {
  // const [certName, setSertName] = useState();

  const { commonName } = useContext(Context);

  // const getCertName = GetCertificateName(certificateList);

  // useEffect(() => {
  //   setSertName(getCertName[0]?.name);
  // }, [getCertName]);

  const [value, setValue] = useState('');

  const handleInputChange = (event) => {
    setValue(event.target.value);
   // setCommonName(event.target.value)
  }

  const handleInputBlur = (event) => {
      if(!value) {
        setValue(commonName);
      } 
  }
  return (
    <>
      <Form>
        <div className="input-group  mb-3">
          <span className="input-group-text" id="inputGroup-sizing-default">
            CN
          </span>
          <input
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            type="text"
            placeholder={value}
            className="form-control"
            aria-label="Sizing example input"
            aria-describedby="inputGroup-sizing-default"
            defaultValue={value || commonName}
          />
        </div>
        <GetTestCertificate path={props.path} commonName={value || commonName} text="Получить сертификат"  />
      </Form>
    </>
  );
};

export default TestTemplate;
