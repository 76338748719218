export default class Api {
  static API_BASE_URL =
    "production" === process.env.NODE_ENV
      ? "/test-smarttoken/api/public"
      : "http://127.0.0.1:8000";

  // Request License:
  async opreglic(name, email, organization, phone) {
    return Api.#executeRestEndpoint("/opreglic/", {
      name: name,
      email: email,
      organization: organization,
      phone: phone,
    });
  }

  // Request Certificate:
  async opcreatecert(certreq) {
    return Api.#executeRestEndpoint("/opcreatecert/", {
      certreq: certreq,
    });
  }

  // Request Verification:
  async opverifysig(sig, doc) {
    return Api.#executeRestEndpoint("/opverifysig/", {
      sig,
      doc,
    });
  }

  async uploadFile(file, dir, name) {
    const body = new FormData();
    body.append("file", file);
    body.append("dir", dir);
    body.append("name", name);
    return fetch(`${Api.API_BASE_URL}/upload-file/`, {
      method: "POST",
      body,
    })
      .then((response) => response.json())
      .catch(() => ({
        error: "Произошла ошибка при обращении к серверу",
      }));
  }

  static async #executeRestEndpoint(endpoint, data) {
    return fetch(`${Api.API_BASE_URL}${endpoint}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .catch(() => ({
        error: "Произошла ошибка при обращении к серверу",
      }));
  }
}
