import { useState } from "react";
import Nav from "../../Navbar";
import Footer from "../../Footer";
import { Container, Row, Col } from "react-bootstrap";
import PortalForm from "../../Forms/Portal/PortalForm";
import Context from "../../../context/context";
import { ApiProvider } from "../../../context/Api";
import { path } from "../../../store/constants";
const Portal = () => {
  // let showStep = 1;
  // if ("production" != process.env.NODE_ENV) {
  //   showStep = 1;
  // }

  const [step, setStep] = useState(1);
  const [certificateList, setCertificateList] = useState("");
  const [certificate, setCertificate] = useState("");
  const [commonName, setCommonName] = useState("Demo");
  const [resetTabStep, setResetTabStep] = useState("false");
  const [waitingStep3, setWaitingStep3] = useState(false);
  const [waitCertList, setWaitCertList] = useState(true);
  
  return (
    <>
      <Nav />
      <Container>
        <Row className="mt-5">
          <Col>
            <Context.Provider
              value={{
                path,
                step,
                setStep,
                certificateList,
                setCertificateList,
                certificate,
                setCertificate,
                commonName,
                setCommonName,
                resetTabStep,
                setResetTabStep,
                waitingStep3,
                setWaitingStep3,
                waitCertList, 
                setWaitCertList
              }}
            >
              <ApiProvider>
                <PortalForm />
              </ApiProvider>
            </Context.Provider>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Portal;
